// Font
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Condensed&display=swap');
$font-family-base: 'IBM Plex Sans Condensed', sans-serif;

// Bootstrap
@import "bootstrap/scss/bootstrap";

//Font Awesome
$fa-font-path: "/node_modules/font-awesome/fonts";
@import "font-awesome/scss/font-awesome.scss";

//Animate.css
@import "/node_modules/animate.css/animate.css";

//Ridiculously Responsive Social Sharing Buttons
@import "/node_modules/rrssb/css/rrssb.css";

footer {
  bottom: 0;
}

$foresight-primary-text-color: #fff;
$foresight-primary-color: #086abb;
$foresight-primary-dark-color: darken($foresight-primary-color, 20%);
$foresight-primary-light-color: lighten($foresight-primary-color, 20%);

$foresight-secondary-text-color: #fff;
$foresight-secondary-color: #23afe6;
$foresight-secondary-dark-color: darken($foresight-secondary-color, 20%);
$foresight-secondary-light-color: lighten($foresight-secondary-color, 20%);

.btn-foresight-primary {
  color: $foresight-primary-text-color;
  background-color: $foresight-primary-color;
  border-color: $foresight-primary-color; }

.btn-foresight-primary:hover {
  color: $foresight-primary-text-color;
  background-color: $foresight-primary-dark-color;
  border-color: $foresight-primary-dark-color; }

.btn-foresight-primary:focus, .btn-foresight-primary.focus {
  box-shadow: 0 0 0 0.2rem $foresight-primary-color; }

.btn-foresight-primary.disabled, .btn-foresight-primary:disabled {
  color: $foresight-primary-text-color;
  background-color: $foresight-primary-color;
  border-color: $foresight-primary-color; }

.btn-foresight-primary:not(:disabled):not(.disabled):active, .btn-foresight-primary:not(:disabled):not(.disabled).active, .show > .btn-foresight-primary.dropdown-toggle {
  color: $foresight-primary-text-color;
  background-color: $foresight-primary-dark-color;
  border-color: $foresight-primary-dark-color; }

.btn-foresight-primary:not(:disabled):not(.disabled):active:focus, .btn-foresight-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-foresight-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem $foresight-primary-color; }


.btn-outline-foresight-primary {
  color: $foresight-primary-color !important;
  background-color: $foresight-primary-text-color;
  border-color: $foresight-primary-color; }

.btn-outline-foresight-primary:hover {
  color: $foresight-primary-text-color!important;
  background-color: $foresight-primary-color;
  border-color: $foresight-primary-color; }

.btn-outline-foresight-primary:focus, .btn-foresight-primary.focus {
  box-shadow: 0 0 0 0.2rem $foresight-primary-color; }

.btn-outline-foresight-primary.disabled, .btn-foresight-primary:disabled {
  color: $foresight-primary-text-color;
  background-color: $foresight-primary-color;
  border-color: $foresight-primary-color; }

.btn-outline-foresight-primary:not(:disabled):not(.disabled):active, .btn-outline-foresight-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-foresight-primary.dropdown-toggle {
  color: $foresight-primary-text-color;
  background-color: $foresight-primary-dark-color;
  border-color: $foresight-primary-dark-color; }

.btn-outline-foresight-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-foresight-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-foresight-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem $foresight-primary-color; }


.btn-foresight-secondary {
  color: $foresight-secondary-text-color;
  background-color: $foresight-secondary-color;
  border-color: $foresight-secondary-color; }

.btn-foresight-secondary:hover {
  color: $foresight-secondary-text-color;
  background-color: $foresight-secondary-dark-color;
  border-color: $foresight-secondary-dark-color; }

.btn-foresight-secondary:focus, .btn-foresight-secondary.focus {
  box-shadow: 0 0 0 0.2rem $foresight-secondary-color; }

.btn-foresight-secondary.disabled, .btn-foresight-secondary:disabled {
  color: $foresight-secondary-text-color;
  background-color: $foresight-secondary-color;
  border-color: $foresight-secondary-color; }

.btn-foresight-secondary:not(:disabled):not(.disabled):active, .btn-foresight-secondary:not(:disabled):not(.disabled).active, .show > .btn-foresight-secondary.dropdown-toggle {
  color: $foresight-secondary-text-color;
  background-color: $foresight-secondary-dark-color;
  border-color: $foresight-secondary-dark-color; }

.btn-foresight-secondary:not(:disabled):not(.disabled):active:focus, .btn-foresight-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-foresight-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem $foresight-secondary-color; }

.btn-outline-foresight-secondary {
  color: $foresight-secondary-color !important;
  background-color: $foresight-secondary-text-color;
  border-color: $foresight-secondary-color; }

.btn-outline-foresight-secondary:hover {
  color: $foresight-secondary-text-color!important;
  background-color: $foresight-secondary-color;
  border-color: $foresight-secondary-color; }

.btn-outline-foresight-secondary:focus, .btn-foresight-secondary.focus {
  box-shadow: 0 0 0 0.2rem $foresight-secondary-color; }

.btn-outline-foresight-secondary.disabled, .btn-foresight-secondary:disabled {
  color: $foresight-secondary-text-color;
  background-color: $foresight-secondary-color;
  border-color: $foresight-secondary-color; }

.btn-outline-foresight-secondary:not(:disabled):not(.disabled):active, .btn-outline-foresight-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-foresight-secondary.dropdown-toggle {
  color: $foresight-secondary-text-color;
  background-color: $foresight-secondary-dark-color;
  border-color: $foresight-secondary-dark-color; }

.btn-outline-foresight-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-foresight-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-foresight-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem $foresight-secondary-color; }

.badge-foresight-primary {
  color: $foresight-primary-text-color;
  background-color: $foresight-primary-color;
}

.badge-foresight-primary[href]:hover, .badge-foresight-primary[href]:focus {
  color: $foresight-primary-text-color;
  text-decoration: none;
  background-color: $foresight-primary-dark-color; 
}

.text-foresight-primary {
  color: $foresight-primary-color !important;
}

.text-foresight-primary-text {
  color: $foresight-primary-text-color !important;
}

.text-foresight-secondary {
  color: $foresight-secondary-color !important;
}

.text-foresight-secondary-text {
  color: $foresight-secondary-text-color !important;
}

.bg-foresight-primary {
  background-color: $foresight-primary-color !important;
}

a.bg-foresight-primary:hover, a.bg-foresight-primary:focus,
button.bg-foresight-primary:hover,
button.bg-foresight-primary:focus {
  background-color: $foresight-primary-dark-color !important; }

.bg-foresight-secondary {
  background-color: $foresight-secondary-color !important;
}

a.bg-foresight-secondary:hover, a.bg-foresight-secondary:focus,
button.bg-foresight-secondary:hover,
button.bg-foresight-secondary:focus {
  background-color: $foresight-secondary-dark-color !important; }

.resource-dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #6c757d;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .resource-dropdown-item:hover, .resource-dropdown-item:focus {
    color: $foresight-secondary-text-color;
    text-decoration: none;
    background-color: $foresight-secondary-color; }
  .resource-dropdown-item.active, .resource-dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #6c757d; }
  .resource-dropdown-item.disabled, .resource-dropdown-item:disabled {
    color: #6c757d;
    background-color: transparent; }

.navbar-nav  a { color:#6c757d; }

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bg-foresight-primary-svg{fill: $foresight-primary-color ;}
.bg-foresight-secondary-svg{fill: $foresight-secondary-color ;}
.svg-vertical-flip {
  transform:
  rotate(90deg)
  rotate(90deg);
}

.container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.border-foresight-primary {
  border-color: $foresight-primary-color !important;
}

.border-foresight-secondary {
  border-color: $foresight-secondary-color !important;
}

.display-5 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-5-5 {
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-6 {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.2;
}

@media (max-width: 575.98px) {
    .display-1 {
      font-size: 3.7rem;
      font-weight: 300;
      line-height: 1.0;
    }

    .display-2 {
        font-size: 2.75rem;
        font-weight: 300;
        line-height: 1.0;
    }

    .display-3 {
        font-size: 2.25rem;
        font-weight: 300;
        line-height: 1.0;
    }

    .display-4 {
        font-size: 1.75rem;
        font-weight: 300;
        line-height: 1.0;
    }

    .display-5 {
        font-size: 1.50rem;
        font-weight: 300;
        line-height: 1.0;
    }

    .display-5-5 {
      font-size: 1.25rem;
      font-weight: 300;
      line-height: 1.0;
    }

    .display-6 {
        font-size: 1rem;
        font-weight: 300;
        line-height: 1.0;
    }
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1030;
}

.left-sidebar {
  position: fixed;
  top: 50%;
  left: 0;
  z-index: 1000;
  border-radius: 0 5px 5px 0;
  padding: 1em;
  box-shadow: 0 0 0 0.2rem $foresight-secondary-color;
  width: 150px;
  word-wrap: break-spaces;
}

.right-sidebar {
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 1000;
  border-radius: 5px 0 0 5px;
  padding: 1em;
  box-shadow: 0 0 0 0.2rem $foresight-secondary-color;
  width: 150px;
  word-wrap: break-spaces;
}

.table-foresight-secondary>tbody>tr:nth-child(odd)>td, 
.table-foresight-secondary>tbody>tr:nth-child(odd)>th {
   background-color: $foresight-secondary-color
}

.table-foresight-secondary>tbody>tr:nth-child(even)>td, 
.table-foresight-secondary>tbody>tr:nth-child(even)>th {
   background-color: $foresight-secondary-light-color
}

@for $i from 1 through 5 {
  .viewport-#{$i * 20 } {
    min-height: #{$i * 20 }vh;
  }
}
